/**
 * DropDown menu expander
 */
function DropDown(elements) {
    this.elements =elements;
    this.activeElement =false;

    for(var i=0; i<elements.length; i++) {

        //quick scan to find activeElements in original html
        if(elements[i].parentNode.parentNode.classList.contains('current_page_item')
         ||elements[i].parentNode.parentNode.classList.contains('current_page_ancestor')) {
            this.activeElement = elements[i].parentNode.parentNode;
            this.activeElement.classList.add('drop-down-active');
        }

        elements[i].addEventListener('click', function(event){
            event.preventDefault();
            var el =event.target.parentNode.parentNode;
            var c ='drop-down-active';

            if(el.classList.contains(c)) {
                el.classList.remove(c);
                this.activeElement =false;
            } else {    
                if(this.activeElement) 
                    this.activeElement.classList.remove(c);

                el.classList.add(c);
                this.activeElement =el;
            }
        }.bind(this));
    }
}

module.exports = DropDown;