var DropDown =require('./DropDown.js');
var els = document.getElementsByClassName('page_item_has_children');
var buttons = [];

for(var i=0; i<els.length; i++) {
	var but =document.createElement('span');
	buttons.push(but);
	els[i].children[0].appendChild(but);
}
var menuDropDowns = new DropDown(buttons);


var $ =window.jQuery;
var navLinks =$('#nav-menu');


$('#nav-toggle').click(function(){
	console.log("hello");
	$(this).toggleClass('is-active');
	$(navLinks).slideToggle(400, 'easeInOut');
});


jQuery.extend( jQuery.easing, {
	easeInOut: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t + b;
		return -c/2 * ((--t)*(t-2) - 1) + b;
	}
});


